import { Grid, makeStyles } from '@material-ui/core';
import content from '../../../content/index/products.json';
import {
	defaultSectionWidth,
	largeText,
	pageSubtitle,
	pageTitle,
	smallSubtitle,
	smallText,
	tinyText,
} from '../../../styles/dimensions';
import colors from '../../../styles/colors';
import Rigraf from '../../../fonts/rigraf/regular/rigraf';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Routes } from '../../../consts/routes';
import { useCallback, useRef } from 'react';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';

const Products = () => {
	const classes = useClasses();

	const carouselRef = useRef<HTMLDivElement>(null);
	const arrowContainerRef = useRef<HTMLDivElement>(null);

	const handleScrollLeft = useCallback(() => {
		carouselRef.current.scrollTo({
			behavior: 'smooth',
			left: carouselRef.current.scrollLeft - carouselRef.current.firstElementChild.clientWidth - 16,
		});
	}, []);

	const handleScrollRight = useCallback(() => {
		carouselRef.current.scrollTo({
			behavior: 'smooth',
			left: carouselRef.current.scrollLeft + carouselRef.current.firstElementChild.clientWidth + 16,
		});
	}, []);

	const handleCarouselScroll = useCallback(() => {
		Array.from(arrowContainerRef.current.children).forEach((item) => {
			item.firstElementChild.classList.remove(classes.arrowDisabled);
		});

		if (carouselRef.current.scrollLeft <= 0) {
			arrowContainerRef.current.firstElementChild.firstElementChild.classList.add(classes.arrowDisabled);
		} else if (
			carouselRef.current.scrollLeft + carouselRef.current.clientWidth >=
			carouselRef.current.scrollWidth
		) {
			arrowContainerRef.current.lastElementChild.firstElementChild.classList.add(classes.arrowDisabled);
		}
	}, []);

	return (
		<section className={classes.section} data-scroll data-scroll-speed="2">
			<h2 className={classes.title}>{content.title}</h2>

			<Grid container classes={{ root: classes.headerGrid }}>
				<Grid item xs={12} sm={5} md={4}>
					<p className={classes.building}>{content.building}</p>
					<p className={classes.projects}>{content.projects}</p>
				</Grid>

				<Grid item sm={2} md={4}></Grid>

				<Grid item xs={12} sm={5} md={4} ref={arrowContainerRef} className={classes.controlsContainer}>
					<div onClick={handleScrollLeft} className={classes.arrowContainer}>
						<StaticImage
							src="../../../images/index/arrow_right_with_circle.svg"
							alt="Slide right icon"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							role="button"
							className={`${classes.arrowLeft} ${classes.arrowDisabled}`}
						/>
					</div>
					<div onClick={handleScrollRight} className={classes.arrowContainer}>
						<StaticImage
							src="../../../images/index/arrow_right_with_circle.svg"
							alt="Slide right icon"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							role="button"
							className={classes.arrowRight}
						/>
					</div>
				</Grid>
			</Grid>

			<div ref={carouselRef} className={classes.carousel} onScroll={handleCarouselScroll}>
				<Link to={Routes.showcaseQap} className={classes.projectLink}>
					<StaticImage
						src="../../../images/index/qap.jpg"
						alt="QAP application showcase"
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						className={classes.carouselImage}
					/>
					<h4 className={classes.projectTitle}>{content.projectItems.qap.title}</h4>
					<span className={classes.projectPlatform}>{content.projectItems.qap.platform}</span>
				</Link>

				<Link to={Routes.showcaseVestur} className={classes.projectLink}>
					<StaticImage
						src="../../../images/index/vestur.jpg"
						alt="Vestur application showcase"
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						className={classes.carouselImage}
					/>
					<div className={classes.projectTitleContainer}>
						<h4 className={classes.projectTitle}>{content.projectItems.vestur.title}</h4>
						<span className={classes.smartCityChip}>{content.smartCity}</span>
					</div>
					<span className={classes.projectPlatform}>{content.projectItems.vestur.platform}</span>
				</Link>

				<Link to={Routes.showcaseEnzkreis} className={classes.projectLink}>
					<StaticImage
						src="../../../images/index/enzkreis.jpg"
						alt="Enzkreis application showcase"
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						className={classes.carouselImage}
					/>
					<div className={classes.projectTitleContainer}>
						<h4 className={classes.projectTitle}>{content.projectItems.enzkreis.title}</h4>
						<span className={classes.smartCityChip}>{content.smartCity}</span>
					</div>
					<span className={classes.projectPlatform}>{content.projectItems.enzkreis.platform}</span>
				</Link>

				<Link to={Routes.showcaseTerra} className={classes.projectLink}>
					<StaticImage
						src="../../../images/index/terra.jpg"
						alt="Terra application showcase"
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						className={classes.carouselImage}
					/>
					<h4 className={classes.projectTitle}>{content.projectItems.terra.title}</h4>
					<span className={classes.projectPlatform}>{content.projectItems.terra.platform}</span>
				</Link>

				<Link to={Routes.showcaseStabler} className={classes.projectLink}>
					<StaticImage
						src="../../../images/index/stabler.jpg"
						alt="Stabler application showcase"
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						className={classes.carouselImage}
					/>
					<h4 className={classes.projectTitle}>{content.projectItems.stabler.title}</h4>
					<span className={classes.projectPlatform}>{content.projectItems.stabler.platform}</span>
				</Link>
			</div>
		</section>
	);
};

export default Products;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		padding: '0 1em',
	},
	headerGrid: {
		marginBottom: '4em',
	},
	title: {
		margin: 0,
		...pageTitle,
		'@media (max-width: 1199px)': {
			...pageSubtitle,
		},
		'@media (max-width: 599px)': {
			...smallSubtitle,
			marginBottom: '0.5em',
		},
	},
	controlsContainer: {
		textAlign: 'right',
		alignSelf: 'flex-end',
		'@media (min-width: 960px)': {
			paddingLeft: '0.5em',
		},
		'@media (min-width: 1232px)': {
			paddingLeft: '1.25em',
		},
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	arrowContainer: {
		display: 'inline-block',
	},
	building: {
		margin: 0,
		marginBottom: '0.3em',
		color: colors.textGrey,
	},
	projects: {
		whiteSpace: 'pre-wrap',
		margin: 0,
		color: colors.textGrey,
		fontFamily: Rigraf.regular,
		letterSpacing: '0.075em',
	},
	projectLink: {
		display: 'inline-flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '0 1em',
	},
	projectTitle: {
		...pageSubtitle,
		marginTop: '0.5em',
		marginBottom: '0.25rem',
		display: 'inline-block',
		'@media (max-width: 959px)': {
			...largeText,
		},
	},
	projectPlatform: {
		color: colors.textGrey,
	},
	arrowLeft: {
		rotate: '180deg',
		marginRight: '1.5em',
		cursor: 'pointer',
		borderRadius: '50%',
		transition: 'opacity 0.15s',
	},
	arrowRight: {
		cursor: 'pointer',
		borderRadius: '50%',
		transition: 'opacity 0.15s',
	},
	arrowDisabled: {
		opacity: 0.5,
		cursor: 'auto',
	},
	carousel: {
		overflowX: 'scroll',
		whiteSpace: 'nowrap',
		margin: '0 -1em',
		'-ms-overflow-style': 'none',
		scrollbarWidth: 'none',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	carouselImage: {
		maxHeight: '511px',
		maxWidth: '384px',
		'@media (min-width: 600px) and (max-height: 749px)': {
			maxHeight: '460px',
		},
		'@media (min-width: 600px) and (max-height: 699px)': {
			maxHeight: '410px',
		},
	},
	projectTitleContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		marginTop: '1.25em',
		marginBottom: '0.25rem',

		'& > h4': {
			marginTop: 0,
			marginBottom: 0,
		},
	},
	smartCityChip: {
		backgroundColor: colors.textBlack,
		color: colors.white,
		fontFamily: RigrafExpanded.semiBold,
		padding: '12px',
		fontSize: smallText.fontSize,
		lineHeight: 1,

		'&::after': {
			content: "'|'",
			marginLeft: '12px',
			fontFamily: RigrafExpanded.light,
		},

		'@media (max-width: 599px)': {
			fontSize: tinyText.fontSize,
		},
	},
});
