import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import { smallText } from '../../styles/dimensions';
import colors from '../../styles/colors';
import { Content } from '../sections/blog/blog';
import { removeWidows } from 'string-remove-widows';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useCallback } from 'react';
import { useScrollContext } from '../../hooks/useScrollContext';

interface Props {
	content: Content;
}

const TinyArticle = (props: Props) => {
	const { content } = props;

	const scroll = useScrollContext();

	const classes = useClasses();

	const handleImageLoad = useCallback(() => {
		if (scroll) {
			scroll.update();
		}
	}, [scroll]);

	return (
		<Grid item xs={12} md={6} className={classes.gridItem}>
			<Link to={content.path}>
				<article className={classes.article}>
					<GatsbyImage
						image={content.src}
						alt={content.alt}
						id="largeArticleImage"
						className={classes.image}
						onLoad={handleImageLoad}
					/>
					<div className={classes.articleTextWrapper}>
						<h5
							className={classes.title}
							dangerouslySetInnerHTML={{ __html: removeWidows(content.title).res }}></h5>
						<span className={classes.author}>{content.author}</span>
					</div>
				</article>
			</Link>
		</Grid>
	);
};

export default TinyArticle;

const useClasses = makeStyles({
	gridItem: {
		alignSelf: 'center',
	},
	article: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	image: {
		maxWidth: 'calc(33% - 2em)',
		marginRight: '2em',
		objectFit: 'contain',
		'@media (max-width: 599px)': {
			maxWidth: 'calc(33% - 1em)',
			marginRight: '1em',
		},
	},
	articleTextWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignSelf: 'stretch',
	},
	title: {
		marginTop: 0,
		marginBottom: '0.2em',
	},
	author: {
		...smallText,
		color: colors.textGrey,
	},
});
