import { Divider, Grid, makeStyles } from '@material-ui/core';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import colors from '../../../styles/colors';
import { defaultSectionWidth, emphasizedSizeText, smallSubtitle } from '../../../styles/dimensions';
import { Content, Response, Category } from '../blog/blog';
import content from '../../../content/index/blog.json';
import LargeArticle from '../../articles/largeArticle';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import TinyArticle from '../../articles/tinyArticle';

const Blog = () => {
	const classes = useClasses();

	const repeatingContent = useMemo(() => {
		const elements: JSX.Element[] = [];
		for (let i = 0; i < 100; i++) {
			elements.push(
				<span key={i} className={classes.cdNewsSpan}>
					{content.cdNews}
				</span>
			);
		}
		return elements;
	}, []);

	const blogPostsQuery: Response = useStaticQuery(graphql`
		query BlogPostsQuery {
			allMdx(limit: 5, sort: { fields: frontmatter___date, order: DESC }) {
				edges {
					node {
						frontmatter {
							title
							author
							category
							date(formatString: "MMM DD, YYYY")
							src {
								childImageSharp {
									gatsbyImageData
								}
							}
							alt
							description
						}
						slug
					}
				}
			}
		}
	`);

	const articles: Content[] = useMemo(() => {
		return blogPostsQuery.allMdx.edges.map((item) => {
			return {
				title: item.node.frontmatter.title,
				description: item.node.frontmatter.description,
				author: item.node.frontmatter.author,
				category: item.node.frontmatter.category as Category,
				date: item.node.frontmatter.date,
				src: item.node.frontmatter.src.childImageSharp.gatsbyImageData,
				alt: item.node.frontmatter.alt,
				path: '/blog/' + item.node.slug,
			};
		});
	}, []);

	return (
		<section>
			<div aria-label={content.cdNews} data-scroll data-scroll-speed="1">
				<div className={classes.cdNews} aria-hidden="true">
					{repeatingContent}
				</div>
			</div>

			<div className={classes.postsContainer} data-scroll data-scroll-speed="2">
				<Link to={articles[0].path}>
					<Grid container className={classes.grid} spacing={4}>
						<LargeArticle content={articles[0]} showDescription={false} />
					</Grid>
				</Link>
				<Grid container spacing={4}>
					<Grid item xs={12} classes={{ item: classes.dividerItem }}>
						<Divider classes={{ root: classes.divider }} />
					</Grid>
					{articles.map((item, key) => {
						return key !== 0 ? <TinyArticle key={key} content={item} /> : null;
					})}
				</Grid>
			</div>
		</section>
	);
};

export default Blog;

const useClasses = makeStyles({
	cdNews: {
		overflow: 'visible',
		whiteSpace: 'nowrap',
		width: '672em',
		paddingTop: '1em',
		paddingBottom: '1em',
		userSelect: 'none',
		animation: '$loop 80s linear infinite',
	},
	cdNewsSpan: {
		display: 'inline',
		margin: 0,
		padding: '0px 0.75em',
		fontFamily: RigrafExpanded.semiBold,
		fontWeight: 'normal',
		...emphasizedSizeText,
		color: colors.textBlack,
		letterSpacing: '1.5px',
		'@media (max-width: 599px)': {
			fontSize: smallSubtitle.fontSize,
			lineHeight: smallSubtitle.lineHeight,
			padding: '0px 0.625em',
		},
	},
	'@keyframes loop': {
		from: {
			transform: 'translateX(-50%)',
		},
		to: {
			transform: 'translateX(0%)',
		},
	},
	postsContainer: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		marginTop: '2.25em',
		marginBottom: '5.25em',
		paddingLeft: '1em',
		paddingRight: '1em',
		'@media (max-width: 599px)': {
			marginBottom: '4.25em',
			marginTop: 0,
		},
	},
	grid: {
		'&>div:first-child': {
			'@media (max-width: 599px)': {
				paddingBottom: '0 !important',
			},
		},
	},
	dividerItem: {
		'@media (min-width: 600px)': {
			display: 'none',
		},
	},
	divider: {
		borderColor: colors.dividerGray,
	},
});
