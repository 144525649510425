import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import { useState, useRef, useEffect, useCallback, MouseEvent as ReactMouseEvent, useMemo } from 'react';
import content from '../../../content/index/intro.json';
import colors from '../../../styles/colors';
import { defaultSectionWidth, pageSubtitle, pageTitle, regularText, smallSubtitle } from '../../../styles/dimensions';
import { ScrollEvent, useScrollContext } from '../../../hooks/useScrollContext';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import { StaticImage } from 'gatsby-plugin-image';
import { splitData } from '../../../helpers/splitData';

const Intro = () => {
	const [scrolling, setScrolling] = useState(false);
	const [scrolledBellow, setScrolledBellow] = useState(false);

	const headerRef = useRef<HTMLHeadingElement>(null);
	const aboutRef = useRef<HTMLDivElement>(null);

	const scroll = useScrollContext();

	useEffect(() => {
		splitData(1.4, 0.025);

		setTimeout(() => {
			headerRef.current.style.opacity = '1';
		}, 1);
	}, []);

	useEffect(() => {
		if (scroll && !scrolling) {
			scroll.on('scroll', watchScroll);
		}
	}, [scroll, scrolling]);

	const classes = useClasses();

	const bannerImages = useMemo(() => {
		return (
			<div className={classes.imagesContainer}>
				<StaticImage
					src="../../../images/index/brain.png"
					alt=""
					layout="constrained"
					loading="lazy"
					placeholder="tracedSVG"
					objectFit="contain"
					aria-hidden="true"
					className={classes.brain}
				/>
				<StaticImage
					src="../../../images/index/circle.png"
					alt=""
					layout="constrained"
					loading="lazy"
					placeholder="blurred"
					aria-hidden="true"
					className={classes.circle}
					width={727}
					height={726}
				/>
			</div>
		);
	}, []);

	const scrollDownImage = useMemo(() => {
		return (
			<StaticImage
				src="../../../images/index/arrow_with_circle.svg"
				alt="Scroll down to text"
				layout="constrained"
				loading="eager"
				placeholder="tracedSVG"
				objectFit="contain"
				width={227}
				height={227}
			/>
		);
	}, []);

	const watchScroll = useCallback(
		(event: ScrollEvent) => {
			if (event.scroll.y >= 200 && event.scroll.y <= aboutRef.current.offsetTop - 100) {
				setScrolling(true);
				scroll.stop();
				scroll.off('scroll', watchScroll);
				if (scrolledBellow) {
					scroll.scrollTo('top', {
						callback: () => {
							setScrolledBellow(false);
							scroll.start();
							setScrolling(false);
						},
					});
				} else {
					scroll.scrollTo(aboutRef.current, {
						callback: () => {
							setScrolledBellow(true);
							scroll.start();
							setScrolling(false);
						},
					});
				}
			}
		},
		[scroll, scrolledBellow]
	);

	const handleArrowClick = useCallback(
		(event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
			if (scroll && !scrolling) {
				event.preventDefault();

				setScrolling(true);
				scroll.stop();
				scroll.off('scroll', watchScroll);
				scroll.scrollTo(aboutRef.current, {
					callback: () => {
						setScrolledBellow(true);
						scroll.start();
						setScrolling(false);
					},
				});
			}
		},
		[scroll]
	);

	return (
		<section className={classes.section}>
			<div className={classes.defaultWidth}>
				<div data-scroll data-scroll-speed="3">
					<div className={classes.bannerContainer}>
						<h1 className={classes.header} data-splitting ref={headerRef}>
							{content.header}
						</h1>
						{bannerImages}
					</div>
					<Link to="#about" onClick={handleArrowClick} className={classes.scrollDownLink}>
						{scrollDownImage}
					</Link>
				</div>

				<Grid container className={classes.grid}>
					<Grid item sm={1} classes={{ item: classes.descriptionColumn }}></Grid>
					<Grid item xs={12} sm={10} id="about" classes={{ item: classes.descriptionColumn }} ref={aboutRef}>
						<p className={classes.description} data-scroll data-scroll-speed="2">
							<span>{content.descriptionBody}</span>
							<span className={classes.descriptionEnd}>{content.descriptionEnd}</span>
						</p>
					</Grid>
				</Grid>
			</div>
		</section>
	);
};

export default Intro;

const useClasses = makeStyles({
	defaultWidth: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
	},
	section: {
		pointerEvents: 'none',
		backgroundColor: colors.buttonOutlineBlack,
		paddingTop: 'calc(7.625em + 3px + 5.5em)',
		paddingLeft: '1em',
		paddingRight: '1em',
		position: 'relative',
		zIndex: 1,
		whiteSpace: 'pre-wrap',
		'@media (max-width: 959px)': {
			paddingTop: 'calc(7.625em + 3px)',
		},
		'@media (max-width: 599px)': {
			paddingTop: 'calc(3.75em + 3px + 2em)',
		},
		'@media (min-width: 600px) and (min-height: 1000px)': {
			paddingTop: 'calc(7.625em + 3px + 5.5em + 5vh)',
		},
	},
	header: {
		position: 'relative',
		zIndex: 1,
		pointerEvents: 'auto',
		color: colors.white,
		margin: 0,
		opacity: 0,
		maxWidth: '9.517857143em',
		'& span': {
			...pageTitle,
			fontFamily: RigrafExpanded.bold,
			'@media (max-width: 599px)': {
				...smallSubtitle,
				fontFamily: RigrafExpanded.semiBold,
			},
		},
		'&>span.word': {
			whiteSpace: 'nowrap',
		},

		'@media (max-width: 959px)': {
			marginBottom: '-1.5em',
		},
		'@media (max-width: 599px)': {
			marginBottom: '0.5em',
		},
	},
	scrollDownLink: {
		width: '227px',
		height: '227px',
		display: 'inline-block',
		position: 'absolute',
		pointerEvents: 'auto',
		left: '32.5%',
		top: '55%',
		animation: '$breathing 3s ease-in-out infinite alternate',
		filter: 'blur(0)',
		'@media (max-width: 1049px)': {
			width: '200px',
			height: '200px',
			left: '40%',
			top: '60%',
		},
		'@media (max-width: 959px)': {
			left: 'calc(50% - 100px)',
			top: '75%',
		},
		'@media (max-width: 599px)': {
			width: '88px',
			height: '88px',
			left: 'calc(50% - 44px)',
			top: '40%',
		},
	},
	'@keyframes breathing': {
		from: {
			transform: 'scale(0.9)',
		},
		to: {
			transform: 'scale(1)',
		},
	},
	bannerContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		'@media (max-width: 959px)': {
			flexDirection: 'column',
		},
	},
	grid: {
		marginTop: '3em',
	},
	description: {
		pointerEvents: 'auto',
		color: colors.white,
		fontFamily: RigrafExpanded.regular,
		...pageSubtitle,
		margin: 0,
		'& span:not(:last-of-type)': {
			fontFamily: RigrafExpanded.light,
			...pageSubtitle,
		},
		'@media (max-width: 599px)': {
			...regularText,
			'& span:not(:last-of-type)': {
				...regularText,
			},
		},
	},
	descriptionEnd: {
		fontFamily: RigrafExpanded.semiBold,
		...pageSubtitle,
		whiteSpace: 'nowrap',
		'@media (max-width: 599px)': {
			...regularText,
		},
	},
	descriptionColumn: {
		textAlign: 'center',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	imagesContainer: {
		'@media (max-width: 959px)': {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	brain: {
		'@media (max-width: 959px)': {
			transform: 'translateY(-3em)',
		},
		'@media (max-width: 599px)': {
			transform: 'translateY(-1em)',
		},
	},
	circle: {
		// @ts-ignore
		position: 'absolute !important',
		zIndex: -1,
		'@media (min-width: 960px)': {
			right: 0,
		},
		'@media (min-width: 1232px)': {
			transform: 'translate(28%, -18%)',
		},
		'@media (min-width: 960px) and (max-width: 1231px)': {
			transform: 'translate(28%, -18%)',
			maxWidth: 'calc(100vw - 33.3125em)',
		},
		'@media (max-width: 959px)': {
			transform: 'translate(-2%, -14%)',
			left: '40%',
		},
		'@media (min-width: 780px) and (max-width: 959px)': {
			right: '-40%',
			aspectRatio: '1.001',
			maxWidth: '738px',
		},
		'@media (max-width: 779px)': {
			transform: 'translate(37%, -14%)',
			left: 0,
		},
		'@media (max-width: 599px)': {
			transform: 'translate(37%, -3em)',
			left: 0,
		},
	},
});
