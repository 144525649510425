import Layout from '../components/layout';
import Blog from '../components/sections/index/blog';
import Intro from '../components/sections/index/intro';
import Products from '../components/sections/index/products';
import Thng from '../components/sections/index/thng';
import SEO from '../components/seo';
import { descriptions, titles } from '../consts/seo';

const IndexPage = () => (
	<Layout>
		<SEO title={titles.home} description={descriptions.home} />
		<Intro />
		<Thng />
		<Products />
		<Blog />
	</Layout>
);

export default IndexPage;
