import { Grid, makeStyles } from '@material-ui/core';
import { useCallback, useEffect, useMemo } from 'react';
import { Content } from '../sections/blog/blog';
import MetroSans from '../../fonts/metro-sans/metroSans';
import { smallText, tinyText } from '../../styles/dimensions';
import colors from '../../styles/colors';
import { useScrollContext } from '../../hooks/useScrollContext';
import { GatsbyImage } from 'gatsby-plugin-image';
import { removeWidows } from 'string-remove-widows';

interface Props {
	content: Content;
	showDescription: boolean;
}

const LargeArticle = (props: Props) => {
	const { content, showDescription } = props;

	const scroll = useScrollContext();

	const classes = useClasses();

	const dateTime = useMemo(() => {
		return new Date(content.date).toISOString();
	}, [content.date]);

	const title = useMemo(() => {
		if (content.title.includes('\u2011')) {
			return content.title;
		}
		return removeWidows(content.title).res;
	}, [content.title]);

	const handleImageLoad = useCallback(() => {
		if (scroll) {
			scroll.update();
		}
	}, [scroll]);

	useEffect(() => {
		if (typeof window !== 'undefined' && showDescription) {
			const image = document.getElementById('largeArticleImageContainer').firstElementChild;
			const article = document.getElementById('largeArticle');
			const description = document.getElementsByClassName(classes.description)[0];

			if (image.clientHeight < article.clientHeight) {
				// @ts-ignore
				description.style.marginBottom = '1.5em';
			}
		}
	}, []);

	return (
		<>
			<Grid item xs={12} md={7} className={classes.gridItem} id="largeArticleImageContainer">
				<GatsbyImage
					image={content.src}
					alt={content.alt}
					id="largeArticleImage"
					className={classes.image}
					onLoad={handleImageLoad}
				/>
			</Grid>
			<Grid item xs={12} md={5} className={classes.gridItem}>
				<article id="largeArticle">
					<span className={classes.category}>{content.category}</span>
					<h2 className={classes.header} dangerouslySetInnerHTML={{ __html: title }}></h2>
					{showDescription && (
						<p className={classes.description}>
							{content.description.substr(0, 200)}
							{content.description.length > 200 && '...'}
						</p>
					)}

					<div>
						<p className={classes.author}>{content.author}</p>
						<p className={classes.date}>
							<time dateTime={dateTime}>{content.date}</time>
						</p>
					</div>
				</article>
			</Grid>
		</>
	);
};

export default LargeArticle;

const useClasses = makeStyles({
	category: {
		textTransform: 'uppercase',
		fontFamily: MetroSans.light,
		fontSize: tinyText.fontSize,
		lineHeight: tinyText.lineHeight,
		color: colors.textGrey,
		letterSpacing: '0.10714285714285714em',
	},
	header: {
		marginTop: '0.2em',
		marginBottom: '0.4em',
	},
	description: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.textGrey,
		marginTop: '1em',
		marginBottom: '2.75em',
	},
	author: {
		margin: 0,
		fontFamily: MetroSans.semiBold,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
	},
	date: {
		margin: 0,
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
	},
	gridItem: {
		paddingTop: '2em !important',
		paddingBottom: '2em !important',
	},
	image: {
		minHeight: '200px',
	},
});
