import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import content from '../../../content/index/thng.json';
import colors from '../../../styles/colors';
import { defaultSectionWidth, largeText, smallSubtitle } from '../../../styles/dimensions';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
// @ts-ignore
import backgroundThng from '../../../images/index/showcase_thng_original.jpg';

const Thng = () => {
	const classes = useClasses();

	// const viewRef = useRef<any>(null);

	// const handleMouseMove = React.useCallback(
	// 	(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
	// 		if (
	// 			typeof document !== 'undefined' &&
	// 			scroll?.scroll?.windowWidth >= 960 &&
	// 			scroll?.scroll?.windowWidth < 2000
	// 		) {
	// 			viewRef.current.style.position = 'absolute';
	// 			viewRef.current.style.left = event.pageX + 'px';
	// 			viewRef.current.style.top = event.pageY + scroll.scroll.windowHeight / 2 + 'px';
	// 		} else if (typeof document !== 'undefined' && scroll?.scroll?.windowWidth >= 2000) {
	// 			viewRef.current.style.position = 'absolute';
	// 			viewRef.current.style.left = event.pageX + 'px';
	// 			viewRef.current.style.top = event.pageY + scroll.scroll.windowHeight + 'px';
	// 		}
	// 	},
	// 	[scroll]
	// );

	return (
		<section>
			<div className={classes.thngArticleContainer}>
				<div className={classes.linksContainer} data-scroll data-scroll-speed="2">
					<span className={classes.linkText}>{content.linkText.part1}</span>
					<Link to={content.linkText.linkServices.path} className={classes.linkItem}>
						{content.linkText.linkServices.text}
					</Link>
					<span className={classes.linkText}>{content.linkText.part2}</span>
					<Link to={content.linkText.linkCareers.path} className={classes.linkItem}>
						{content.linkText.linkCareers.text}
					</Link>
					<span className={classes.linkText}>{content.linkText.part3}</span>
				</div>

				<article>
					<div className={`${classes.defaultWidth} ${classes.thngGridWrapper}`}>
						<Link to={content.thng.showcasePath} className={classes.link}>
							<Grid
								container
								// onMouseMove={handleMouseMove}
								className={classes.thngContainer}>
								<Grid
									item
									xs={12}
									sm={2}
									className={classes.viewButtonColumn}
									data-scroll
									data-scroll-speed="2">
									<StaticImage
										src="../../../images/index/view_hover_button_2.svg"
										alt="Display THNG showcase"
										className={classes.viewButton}
										layout="constrained"
										loading="lazy"
										placeholder="none"
									/>
								</Grid>

								<Grid item sm={6}></Grid>

								<Grid
									item
									xs={12}
									sm={4}
									className={classes.thngDataColumn}
									data-scroll
									data-scroll-speed="2">
									<h2 className={classes.thngTitle}>{content.thng.title}</h2>
									<hr className={classes.thngDivider} />

									<div className={classes.thngInnerDiv}>
										<div className={classes.thngContentSection}>
											<h6 className={classes.thngSubtitle}>{content.thng.platform.title}</h6>
											<p className={classes.thngContent}>{content.thng.platform.content}</p>
										</div>

										<div className={classes.thngContentSection}>
											<h6 className={classes.thngSubtitle}>{content.thng.about.title}</h6>
											<p className={classes.thngContent}>{content.thng.about.content}</p>
										</div>
									</div>
								</Grid>
							</Grid>
						</Link>
					</div>
				</article>
			</div>
		</section>
	);
};

export default Thng;

const useClasses = makeStyles({
	link: {
		'-webkit-tap-highlight-color': 'transparent',
	},
	defaultWidth: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
	},
	articleHeader: {
		marginBottom: '0.5em',
		'@media (max-width: 599px)': {
			marginTop: 0,
			marginBottom: '0.9166666666666666em',
		},
	},
	image: {
		marginTop: '-2.625em',
		'@media (max-width: 599px)': {
			marginTop: 0,
			marginBottom: '1.5em',
			maxWidth: 'unset',
			width: 'calc(100% + 2em)',
			transform: 'translateX(-1em)',
		},
	},
	thngArticleContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundImage: `url(${backgroundThng})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPositionX: '43%',
		backgroundPositionY: '65%',
		marginBottom: '5.25em',
		paddingBottom: '18.75em',
		position: 'relative',
		zIndex: 0,
		paddingLeft: '1em',
		paddingRight: '1em',
		'@media (max-width: 749px)': {
			paddingLeft: '0em',
			paddingRight: '0em',
		},
		'@media (min-width: 600px) and (max-width: 749px)': {
			backgroundPositionX: '50%',
		},
	},
	linksContainer: {
		color: colors.white,
		marginTop: '13.5em',
		marginBottom: '22.875em',
		textAlign: 'center',
		padding: '0 1em',
		'@media (max-width: 599px)': {
			marginTop: '7.5em',
			marginBottom: '32.875em',
		},
		'@media (min-width: 390px)': {
			whiteSpace: 'pre-wrap',
		},
	},
	linkText: {
		...smallSubtitle,
		fontFamily: RigrafExpanded.extraLight,
		'@media (max-width: 599px)': {
			...largeText,
		},
	},
	linkItem: {
		...smallSubtitle,
		fontFamily: RigrafExpanded.extraLight,
		textDecoration: 'underline',
		textUnderlineOffset: '3px',
		textDecorationThickness: '1.5px',
		'@media (max-width: 599px)': {
			...largeText,
		},
	},
	thngGridWrapper: {
		'& :hover $viewButton': {
			opacity: 1,
		},
	},
	thngTitle: {
		color: colors.white,
		margin: '0.4em 0',
		'@media (max-width: 599px)': {
			fontSize: smallSubtitle.fontSize,
			lineHeight: smallSubtitle.lineHeight,
			margin: '0.5em 0',
		},
		'@media (min-width: 2000px)': {
			paddingLeft: '2vw',
		},
	},
	thngSubtitle: {
		color: colors.white,
		margin: '0.25em 0',
	},
	thngContent: {
		color: colors.whiteDimmed,
		margin: '0.25em 0',
	},
	thngInnerDiv: {
		paddingTop: '2.5em',
		'@media (min-width: 2000px)': {
			paddingLeft: '2vw',
		},
	},
	thngContentSection: {
		marginBottom: '2em',
	},
	thngDivider: {
		borderColor: colors.white,
		'@media (min-width: 2000px)': {
			transform: 'translateX(2vw)',
		},
	},
	thngDataColumn: {
		'@media (max-width: 599px)': {
			order: 1,
			paddingLeft: '1em',
			paddingRight: '1em',
			position: 'relative',
			top: '-32em',
		},
	},
	thngContainer: {
		padding: '4em 0',
	},
	viewButtonColumn: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		'@media (max-width: 599px)': {
			order: 2,
			justifyContent: 'center',
			position: 'relative',
			top: '18em',
		},
	},
	viewButton: {
		opacity: 0,
		transition: 'opacity 0.25s linear',
		height: '112px',
		width: '112px',
		'@media (max-width: 959px)': {
			opacity: 1,
		},
	},
	grid: {
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	},
});
